<template>
  <div class="contact-content">
    <div class="header">
      <div class="text">
        <h1>CONTACT</h1>
      </div>
    </div>
    <div class="main">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="15" :md="15" :lg="15" :xl="15">
          <div class="leftBox">
            <div class="title">
              <h1>We are here to help you</h1>
            </div>
            <p class="inquisitive">
              Our website is currently in public beta, and we warmly welcome your feedback. If you have any questions regarding the product, please feel free to leave us a message.
            </p>
            <p class="inquisitive">Please feel free to contact us, our customer service center is working for you 24/7.</p>
            <h3 class="fromTitle">CONTACT FORM</h3>
<!--            <div class="contact-form">
              <h3>CONTACT FORM</h3>
            </div>-->
            <el-form :model="formInline" class="demo-form-inline" size="medium">
              <el-row>
                <el-col :span="11">
                <el-form-item label="Your Name" :inline="true">
                  <el-input v-model="formInline.name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="1">
                <span>&nbsp;</span>
              </el-col>
              <el-col :span="11">
                <el-form-item label="Your Email" :inline="true">
                  <el-input
                    v-model="formInline.email"
                  ></el-input>
                </el-form-item>
              </el-col>
              </el-row>
              <el-form-item label="Your Message" :inline="true">
                <el-input v-model="formInline.message" type="textarea"></el-input>
              </el-form-item>
              <el-form-item style="text-align: center">
                <div class="fromBtn">
                  <el-button icon="el-icon-message" @click="onSubmit" v-loading="submitLoading">Send Message</el-button>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
        <el-col :xs="24" :sm="9" :md="9" :lg="9" :xl="9">
          <div class="rightBox">
<!--            <div class="title">
              <h3 style="font-size: 24px;">ADDRESS</h3>
            </div>-->
            <div style="color: #555;margin-top: 5px;font-size: 16px;letter-spacing: 0.08em;">
              <span v-if="config.contact_address">
                <i class="el-icon-map-location"></i>:{{config.contact_address}}<br>
              </span>
              <span v-if="config.contact_phone">
                <i class="el-icon-mobile-phone"></i>:{{config.contact_phone}}<br>
              </span>
              <span v-if="config.contact_email">
                <i class="el-icon-message"></i>:{{config.contact_email}}
              </span>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { submitMessage } from "@/api/contact.js";
import {getHomeData} from "@/api/home.js";
export default {
  mounted() {
    this.getHomeList();
  },
  data() {
    return {
      formInline: {
        name: "",
        email: "",
        message: "",
        token: localStorage.getItem("token"),
      },
      submitLoading:false,
      config: "",
    };
  },
  methods: {
    getHomeList() {
      getHomeData().then((res) => {
        const {config } = res.data;
        this.config = config;
      });
    },
    onSubmit() {
      this.submitLoading = true;
      submitMessage(this.formInline).then((res) => {
        if (res.code === 1) {
          this.formInline.name = "";
          this.formInline.email = "";
          this.formInline.message = "";
          this.$message({
            message: res.msg,
            type: "success",
          });
        } else {
          // this.$message({
          //   message: res.msg,
          //   type: "error",
          // });
        }
        this.submitLoading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  background: url(../../assets/imgs/home/texture-bw.png) center center repeat;
  height: 113px;
  .text {
    max-width: 1140px;
    margin: 0 auto;
    line-height: 113px;
    font-size: 20px;
    color: #333;
  }
  h1 {
    font-weight: bolder;
    line-height: 113px;
    margin-top: 0px;
  }
}
.main {
  height: 100vh;
  max-width: 1160px;
  margin: 0 auto;
  padding: 20px 10px;
  margin-top: 10px;
  margin-bottom: 100px;
  .leftBox {
    h1 {
      font-family: "Roboto", Helvetica, Arial, sans-serif;
      font-weight: bold;
      line-height: 1.1;
      color: #333333;
      font-size: 36px;
    }
    .inquisitive {
      font-family: "Roboto", Helvetica, Arial, sans-serif;
      font-size: 14px;
      margin-top: 20px;
      line-height: 1.42857143;
      color: #555555;
    }
    .fromTitle {
      margin-bottom: 40px;
      font-family: "Roboto", Helvetica, Arial, sans-serif;
      font-size: 24px;
      color: #333;
      display: inline-block;
      border-bottom: solid 5px #38a7bb;
      line-height: 1.1;
      padding-bottom: 10px;
      vertical-align: middle;
      text-transform: uppercase;
      letter-spacing: 0.06em;
      font-weight: bold;
    }
    .fromBtn {
      color: #38a7bb;
      background-color: #ffffff;
      border-color: #38a7bb;
    }
    .fromBtn :hover{
      color: #ffffff;
      background-color: #38a7bb;
      border-color: #ffffff;
    }
  }
  .rightBox {
    margin-left: 40px;
    .title {
      color: #333;
      font-weight: 900;
      text-transform: uppercase;
      letter-spacing: 0.08em;
    }
    h3 {
      font-weight: bold;
    }
  }
}
</style>
<style scoped>
/* @media screen and (min-width: 1600px) {
  .el-col-lg-14 {
    width: 50%;
  }
} */
@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .el-col-lg-14 {
    /* width: 33.3%; */
  }
}
@media screen and (max-width: 800px) {
  .el-col-lg-15 {
    width: 100%;
  }
}
</style>
